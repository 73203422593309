import Language from "Language";

export default class Settings {
    static ready: boolean = false;
    static unit_km: boolean = true;
    static unit_celsius: boolean = true;
    static unit_ps: boolean = true;
    static adminurl: string = "http://raspberry/admin";


    static Distance(km: number) {
        if (!Settings.ready)
            Settings.LoadTranslationFile();

        if (this.unit_km)
            return km;

        let r1 = km * 0.62137119223733;
        let r = Math.round(r1 * 10) / 10;
        return r;
    }

    static Temperature(C: number) {
        if (!Settings.ready)
            Settings.LoadTranslationFile();

        if (this.unit_celsius)
            return C;

        return (C * 9 / 5) + 32;
    }

    static km_miles() {
        if (this.unit_km)
            return Language.t("km");
        else
            return Language.t("miles");
    }

    static kmh_mph() {
        if (this.unit_km)
            return Language.t("km/h");
        else
            return Language.t("mph");
    }

    static celsius_farenheit(): string {
        if (this.unit_celsius)
            return Language.t("°C");
        else
            return Language.t("°F");
    }

    static LoadTranslationFile() {
        Settings.ready = true;
        // this.myhash.set("Date", "Datum");
        var request = new XMLHttpRequest();
        request.open('GET', 'public/plugins/teslalogger-timeline-panel/settings.json?_cache=' + new Date().getTime(), false);
        request.send(null);
        if (request.status === 200) {
            try {
                let j = JSON.parse(request.responseText);

                if (j["Temperature"] === "fahrenheit")
                    this.unit_celsius = false;

                if (j["Length"] === "mile")
                    this.unit_km = false;

                if (j["Power"] === "kw")
                    this.unit_ps = false;

                if (j["URL_Admin"])
                {
                    let u = j["URL_Admin"];
                    if (u.endsWith("/"))
                        u = u.substring(0, u.length-1);

                    this.adminurl = u;
                }
            }
            catch (e) {
                console.log("Parse settings.json Error: " + e.message);
            }
        }
    }
}