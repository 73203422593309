import React from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
//import { css, cx } from 'emotion';
//import { stylesFactory, useTheme } from '@grafana/ui';
import { Parking } from './Parking';
import { Driving } from './Driving';
import { Missing } from './Missing';
import { Charging } from './Charging';
import { FwUpdate } from './FwUpdate';
import { useTheme } from '@grafana/ui';

interface Props extends PanelProps<SimpleOptions> {}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height, replaceVariables}) => {
  const theme = useTheme();
  // const styles = getStyles();
  const isLight = theme.isLight;

  const carid = replaceVariables("$Car");
  console.log("Carid: " + carid);

  const f = data.series[0].fields[1];
  // const s = data.series[0];
  const l = f.values.length;
  const items = [];

  for (let i = 0; i < l; i++) {
    //console.log('Index: ' + i + ' value: ' + f.values.get(i));
    //console.log('test: ' + getData(i, 'Action', data));
    //items.push(<Parking action={s.fields[0].values.get(i)} time={s.fields[0].values.get(i)} />);

    let action = getData(i, 'Action', data);

    if (action === "Missing")
    {
      items.push(
      <Missing
          action={action}
          startDate={getData(i, 'StartDate', data)}
          endDate={getData(i, 'EndDate', data)}
          startAddress={getData(i, 'Start_address', data)}
          odometer={getData(i, 'odometer', data)}
          distance={getData(i, 'distance', data)}
          carid={carid}
          isLight={isLight}
        />);

    } else if (action === "Update")
    {
      items.push(
      <FwUpdate
          action={action}
          startDate={getData(i, 'StartDate', data)}
          endDate={getData(i, 'EndDate', data)}
          startAddress={getData(i, 'Start_address', data)}
          odometer={getData(i, 'odometer', data)}
          distance={getData(i, 'distance', data)}
          carid={carid}
          isLight={isLight}
        />);

    }
    else if (action === "Parking")
    {
      items.push(
        <Parking
          action={action}
          startDate={getData(i, 'StartDate', data)}
          endDate={getData(i, 'EndDate', data)}
          minuteDiff={getData(i, 'MinuteDiff', data)}
          startAddress={getData(i, 'Start_address', data)}
          endAddress={getData(i, 'End_address', data)}
          kWh={getData(i, 'kWh', data)}
          odometer={getData(i, 'odometer', data)}
          rangeDiff={getData(i, 'RangeDiff', data)}
          soc={getData(i, 'SOC', data)}
          socDiff={getData(i, 'SOC_Diff', data)}
          outsideTempAvg={getData(i, 'outside_temp_avg', data)}
          startposid={getData(i, 'startposid', data)}
          endposid={getData(i, 'endposid', data)}
          carid={carid}
          isLight={isLight}
          startLat={getData(i, 'StartLat', data)}
          startLng={getData(i, 'StartLng', data)}
          odometerEnd={getData(i, 'odometerEnd', data)}
          speed_max={getData(i, 'speed_max', data)}
          max_charger_power={getData(i, 'max_charger_power', data)}
          sleephours={getData(i, 'sleephours', data)}
        />
      );
    }
    else if (action === "Charging")
    {
      items.push(
        <Charging
          action={action}
          startDate={getData(i, 'StartDate', data)}
          endDate={getData(i, 'EndDate', data)}
          minuteDiff={getData(i, 'MinuteDiff', data)}
          startAddress={getData(i, 'Start_address', data)}
          endAddress={getData(i, 'End_address', data)}
          kWh={getData(i, 'kWh', data)}
          odometer={getData(i, 'odometer', data)}
          rangeDiff={getData(i, 'RangeDiff', data)}
          soc={getData(i, 'SOC', data)}
          socDiff={getData(i, 'SOC_Diff', data)}
          outsideTempAvg={getData(i, 'outside_temp_avg', data)}
          startposid={getData(i, 'startposid', data)}
          endposid={getData(i, 'endposid', data)}
          carid={carid}
          isLight={isLight}
          startLat={getData(i, 'StartLat', data)}
          startLng={getData(i, 'StartLng', data)}
          cost={getData(i, 'cost', data)}
          csid={getData(i, 'csid', data)}
          currency={getData(i, 'currency', data)}
          odometerEnd={getData(i, 'odometerEnd', data)}
          speed_max={getData(i, 'speed_max', data)}
          max_charger_power={getData(i, 'max_charger_power', data)}
        />
      );
    }
    else
    {
      items.push(
        <Driving
          action={action}
          startDate={getData(i, 'StartDate', data)}
          endDate={getData(i, 'EndDate', data)}
          minuteDiff={getData(i, 'MinuteDiff', data)}
          startAddress={getData(i, 'Start_address', data)}
          endAddress={getData(i, 'End_address', data)}
          kWh={getData(i, 'kWh', data)}
          odometer={getData(i, 'odometer', data)}
          rangeDiff={getData(i, 'RangeDiff', data)}
          soc={getData(i, 'SOC', data)}
          socDiff={getData(i, 'SOC_Diff', data)}
          outsideTempAvg={getData(i, 'outside_temp_avg', data)}
          startposid={getData(i, 'startposid', data)}
          endposid={getData(i, 'endposid', data)}
          carid={carid}
          isLight={isLight}
          startLat={getData(i, 'StartLat', data)}
          startLng={getData(i, 'StartLng', data)}
          cost={getData(i, 'cost', data)}
          csid={getData(i, 'csid', data)}
          odometerEnd={getData(i, 'odometerEnd', data)}
          speed_max={getData(i, 'speed_max', data)}
          max_charger_power={getData(i, 'max_charger_power', data)}
        />
      );
    }
  }

  return <div className="fw">{items}</div>;
};

function getData(rownumber: number, column: string, data: any) {
  const f = data.series[0].fields;
  const c = f.find((x: any) => x.name === column);
  const v = c.values.get(rownumber);
  return v;
}
